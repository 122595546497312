.viz-ui {
  flex: 2;
  display: flex;
  flex-direction: column;
  .layout {
    display: flex;
    align-items: center;

    .disclosure {
      margin-right: 4px;
      color: var(--color-txt-button);
    }
    .labeledSelect label {
      vertical-align: baseline;
      margin-right: 2px;
    }
  }
}

.viz-settings {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin: 7px;
    border: 1px solid var(--color-border-primary)
}

.viz-settings input[type="text"],
.viz-param-settings input[type="text"] {
    width: 50px;
    margin-right: 7px;
    margin-left: 2px;
    text-align: right;
    padding: 2px;
}

.vizSetting {
    padding: 5px;
    margin: 2px;
    display: flex;
    border-radius: 2px;

    input {
      margin-right: 0px !important;
      margin-left: 4px !important;
    }
}

.animateVizToggle {
    margin-right: 2px;
}

.vizParamSettings {
    position: fixed;
    top: 24px;
    padding-bottom: 1px;
    z-index: 200000;
    border-radius: 3px;
    animation: moveVizParam 0.4s;
    background-color: var(--color-bg-tree-row-track);
}

@keyframes moveVizParam {
  0% {
    top: 0px;
  }
  100% {
    top: 20px;
  }
}

.paramName {
    font-style: italic;
    margin-right: 5px;
}

.viz {
    position: absolute;
    opacity: inherit;
    z-index: inherit;
}

.viz-anim1 {
    animation: viz 0.05s linear;
}
@keyframes viz {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}

.viz_spiral, .viz_wave, .viz_flower {
  .bumper {
    display: none;
  }
  .section {
    .label {
      display: none;
    }
  }
}

.viz_text1 {
  .bumper {
    display: none;
  }
}
