// fonts
$default-font-family: 'Lato', sans-serif;

// color-mapping
$bad: $red-200;
$good: $yellow-300;
$excellent: $green-200;
$perfect: $blue-200;


:root {
  --color-border-primary: $color-border-primary;
  --color-border-push-button: $color-border-push-button;
  --color-border-tree-row-active: $color-border-tree-row-active;
  --color-bg-blast-button: $color-bg-blast-button;
  --color-bg-button: $color-bg-button;
  --color-bg-controls: $color-bg-controls;
  --color-bg-input: $color-bg-input;
  --color-bg-interaction-wrapper: $color-bg-interaction-wrapper;
  --color-bg-interaction-wrapper-viz: $color-bg-interaction-wrapper-viz;
  --color-bg-list: $color-bg-list;
  --color-bg-modal: $color-bg-modal;
  --color-bg-score-progress-bar: $color-bg-score-progress-bar;
  --color-bg-sidebar: $color-bg-sidebar;
  --color-bg-timer: $color-bg-timer;
  --color-bg-tree-row-player: $color-bg-tree-row-player;
  --color-bg-tree-row-playlist: $color-bg-tree-row-playlist;
  --color-bg-tree-row-track: $color-bg-tree-row-track;
  --color-bg-tree-row-active: $color-bg-tree-row-active;
  --color-txt-button: $color-txt-button;
  --color-txt-label: $color-txt-label;
  --color-txt-leaderboard-player: $color-txt-leaderboard-player;
  --color-txt-score-summary: $color-txt-score-summary;
  --color-txt-score-box-counter: $color-txt-score-box-counter;
  --color-txt-score-progress: $color-txt-score-progress;
  --color-txt-title: $color-txt-title;
  --color-txt-tree-match-title-active: $color-txt-tree-match-title-active;
  --size-txt-lyrics: 30px; // TODO: keep synced with DEFAULT_FONT_SIZE in constants.ts or initialize in Player.tsx?
  --size-txt-section: 18px;  // TODO: 20 * SECTION_FONT_SIZE_FACTOR = 30 * .6
}