.breakTimerClock {
  margin-left: auto;
  margin-right: auto;
  position:absolute;
  z-index: 10000;
  width: 800px;
  height: 800px;
  top: calc(50% - 400px); // must be 0.5 * COUNTDOWN_CLOCK_SIZE
  .react-countdown-clock {
    top: unset
  }

}
.breakTimerModal {
  border-radius: 12px;
  border: 6px solid var(--color-bg-modal);
  animation: move 0.4s;
  background-color: var(--color-bg-modal);
  position: fixed;
  z-index: 200000;
  max-height: 64%;
  padding: 8px 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-txt-label);
  font-weight: normal;
  outline: none;

  h1 {
    font-size: 14px;
    text-align: center;
    margin-block-end: 0;
    margin-block-start: 4px;
    margin-bottom: 40px;
  }
  .finished {
    font-size: 28px;
  }

  .button {
    background-color: var(--color-bg-button);
    margin-right: 5px;
    padding: 3px 10px 3px 10px;
    min-width: 60px;
    font-size: 14px;
    font-family: $default-font-family;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
    gap: 10px;
    border-radius: 4px;
  }

  .numberInputWithLabel {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    font-family: Lato;
    padding-left: 14px;
    border: solid 2px black;
    border-radius: 4px;
    background-color: var(--color-bg-input);
    color:white;
  }
  

  .inputContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
}

@keyframes flash {
  100% { background-color: #f00; }
}

.flash {
  animation: flash 1.5s infinite;
}
