
.statusSidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  min-height: 500px;
  padding-top: 8px;
  overflow-y: scroll;

  &.hasFocus {
    border: 3px solid var(--color-border-focus);
  }
  &.left {
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 10px;
    padding-right: 10px;
    font-size: x-large;
    min-width: 400px;
    min-height: auto;

    .chatSongSummary {
      font-size: x-large;
      &.rollup {
        .chatScoreProgress.mini {
          background-color: unset !important;
        }
      }
    }
    .chatUser {
      font-size: xx-large;
    }
    .chatScoreProgressWrapper.mini {
      width: 100px;
      height: 24px;
    }
    .chatScoreProgress.mini {
      height: 24px;
    }
    .chatScore.mini {
      width: 100px !important;
      font-size: large;
      padding-top: 1px;
    }
    .sidebarTrackTitle {
      font-size: x-large;
      .matchNodeInfo {
        font-size: large;
      }
    }
    .visibility {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--color-txt-button);
      margin-bottom: 10px;
      span {
        font-size: medium;
        padding-top: 2px;
        padding-right: 4px;
      }
      label {
        color: var(--color-txt-button);
        margin-right: 4px;
        width: auto;
      }
    }
  }
  .header {
    text-decoration: underline;
    padding-bottom: 10px;
    color: var(--color-bg-list);
    cursor: pointer;

    &:hover {
      color: var(--color-txt-tree-match-title-active);
    }
  }
}

.chatDiv {
    position: relative;
    padding-bottom: 5px;
  width: 100%;
}

.chatDiv:last-child {
    border: 0;
}

.sidebarTrackTitle {
  color: black;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  cursor: pointer;
  font-size: 12px;
  font-weight:500;
  margin-bottom: 4px;
  font-weight: 600;
  margin-bottom: 4px;
  font-family: Montserrat;
  margin-top: 6px;

  .artist {
    color: var(--color-txt-label);
    font-style: italic;
    margin-bottom: 4px;
  }
  .matchNodeInfo {
    .points {
      color: black;
      padding-right: 5px;
      font-size:12px
    }
    .pointsSidebar {
      color: #7c7c7c;
      padding-right: 5px;
      font-size: 12px;
      font-family: Montserrat;
      font-weight: 500;
    }
    span {
      color: #7c7c7c;
      font-weight: 500;
    }
  }
}

.chatScoreSectionWrapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px;
    .caption {
      font-style: italic;
      font-size: x-small;
      cursor: pointer;
    }
}

.chatScoreProgressWrapper {
  width: 200px;
    height: 32px;
    //margin-bottom: 9px;
    background-color: var(--color-bg-score-progress-bar);
    border: none;
    position: relative;

    border-radius: 4px;

    &.mini {
      width: 50px;
      height: 20px;

      &.header {
        width: 120px;
      }
    }
}

.chatScoreProgress {
    position: absolute;
    width: 0px;
    max-width: 238px;
    background-color: var(--color-bg-score-progress-bar-progress);
    height: 32px;

    &.perfect {
      background-color: $perfect;
    }

    &.excellent {
      background-color: $excellent;
    }

    &.good  {
      background-color: $good;
    }

    &.bad {
      background-color: $bad;
    }

    &.mini {
      max-width: 120px;
      height: 20px;
      border-radius: 4px;
    }

    &.show {
      background-color: inherit;
    }
}

.chatScore {
    width: 238px !important;
    background-color: transparent !important;
    color: var(--color-txt-score-progress) !important;
    border: none !important;
  font-size: 16px;
  z-index: 10;
  position: relative;
  text-align: center;
  padding-top: 6px;
  &.mini {
    width: 50px !important;
    font-size: 14px;
    padding-top: 1px;

    &.header {
      width: 124px !important;
    }
    &.active {
      font-style: italic;
    }
  }
}

.perfect {
    background-color: $perfect !important;
  color:#07687e !important;
}

.excellent {
    background-color: $excellent !important;
  color:#3a6614 !important;
}

.good {
    background-color: $good !important;
  color:#906d02 !important;
}

.bad {
    background-color: $bad !important;
  color:#481200 !important;
}

.chatScoreBoxWrapper {

    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.chatScoreBoxWrapper input {
    width: 35px;
    margin-right: 1px;
    margin-left: 1px;
    padding: 4px;
    text-align: center;
    color: var(--color-txt-score-box-counter) !important;
}

.chatClockProgressWrapper {
    width: 100px;
    height: 4px;
    margin-top: 2px;
    background-color: var(--color-bg-score-progress-bar);
    border: none;
    display: flex;
    border: 1px solid $color-border-progress-bar;
    border-radius: 2px;
}

.chatClockProgress {
    width: 0px;
    max-width: 100px;
    background-color: $color-bg-score-progress-bar-progress;
    height: 4px;
}

.chatClockProgress.progressLapComplete {
    border-right: 1px solid $color-border-progress-bar;
}

.chatClockProgress.show {
    background-color: inherit;
}

.chatNewTrackButton {
  height: auto;
  font-size: 10px;
  margin: 3px 0 2px 2px;
  max-width: fit-content;
  background-color: #4b7593c0;
  padding: 3px 10px 3px 10px;
  border-radius: 2px !important;
}

.chatSongInfo {
  width: 100%;
  padding-left: 12px;
  &.details {
    text-align: center;
    margin-top: 5px;
  }
  a, span {
    color: var(--color-txt-title);
    text-decoration: none;
    min-width: 100%;
  }
}

.chatMatchOrPlaylistInfo {
  text-align: center;
  color: var(--color-txt-button);
}

.chatTreeExpander {
  width: 24px;
  color: var(--color-txt-button);
  text-align: center;
  font-size: large;
  margin-left: 2px;
  margin-right: 3px;
  border-radius: 32px;


  &.isExpanded {
    background-color: var(--color-bg-tree-row-playlist);
    border: 1px solid var(--color-txt-button);
  }
}

.chatSongSummary {
  display: flex;
  cursor: pointer;
  font-size: 12px;

  .statusLeft {
    flex: 5;
    display: flex;
    align-items: center;
  }

  .statusRight {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column-reverse;
    font-size: 14px;
    .matchNodeInfo {
      min-width: 150px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding-right: 5px;

      .points {
        color: black;
        padding-right: 5px;
        font-size:12px
      }
      .pointsSidebar {
        color: #7c7c7c;
        font-size: 12px;
        font-family: Montserrat;
        font-weight: 500;
      }
      span {
        color: #7c7c7c;
        font-weight: 500;
      }
    }
  }
}

.chatSongSummary.activeSong {
  margin-bottom: -10px;
}

.chatSongSummary.leaf.activeSong {
  margin-bottom: -10px;
  justify-content: flex-end;
}

.chatSongSummaryTitle {
  padding-left: 2px;
  padding-right: 3px;
  color: var(--color-txt-label);
}

.chatSongSummaryTopScore {
  padding: 3px;
  padding-right: 2px;
  cursor: pointer;
}

.chatStatusDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    background-color: var(--color-bg-list);
    border-radius: 32px;
  -webkit-box-shadow: 0px 6px 8px -4px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 6px 8px -4px rgba(0,0,0,0.5);
  box-shadow: 0px 6px 8px -4px rgb(1 1 1 / 50%);
  z-index: 20;

  &.match {
    position: relative;
    justify-content: flex-start;
    cursor: pointer;
    padding-right: 2px;

    &.active {
      box-shadow: 0px 6px 8px -4px rgb(1 1 1 / 50%);
      z-index: 20;
      .title {
        color: var(--color-txt-tree-match-title-active);
      }
    }

    .left {
      //flex: 5;
      display: flex;
      align-items: center;
    }

    .right {
      flex: 3;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: var(--color-txt-button);

      .chatSongSummaryTopScore {
        margin-right: 5px;
      }

      &:hover {
        color: var(--color-hover-txt-button-footer);
      }
      button {
        border-radius: 32px;
      }
    }

    .title {
      padding-right: 10px;
      padding-top: 4px;
      padding-bottom: 4px;
      color: var(--color-txt-tree-match-title);
    }

    .owner {
      color: $color-txt-match-owner;
      font-size: 12px;
      line-height: 1.6;
      margin-right: 5px;
    }
  }
}

.chatUser {
    cursor: pointer;
    color: var(--color-txt-label);
    font-size: 14px;
    &.chatSelf {
      //color: $color-txt-leaderboard-self;
      border: 1px solid $gray-100;
      border-radius: 2px;
      padding: 2px;
    }
}

.chatSongWrapper {
  width: 100%;
  /* border-radius: 12px; */
  padding: 4px;
  opacity: 0.8;
  background-color: #ffffffb0;
  border-top: 1px solid #9f9c9c;

    &.active {
      border: none;
      background-color: var(--color-bg-tree-row-active);
    }
    &.dirty {
      border-right: 1px dashed $color-border-tree-row-dirty;
    }
    &:hover {
      background-color: var(--color-bg-list);
    }
  &:last-child {
    margin-bottom: 2px;
  }
}

.hostPlayer {
  border: 1px dotted $gray-550;
  border-style: dotted;
  opacity: 0.3;
}

.currPlayer {
  //border: 1px solid $color-border-tree-row;
  opacity: 1.0;
}
.matchGroup {
  color: var(--color-bg-list);
  display: flex;
  justify-content: space-between;
text-transform:uppercase;
  font-size:12px;
  margin-top: 20px;
  border-top: 1px solid black;
  padding-top: 20px;
}

.matchWrapper {
    background-color: var(--color-bg-tree-row-player);

}

.playlistWrapper {
  background-color: var(--color-bg-tree-row-playlist);
}

.chatUserSongsWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--color-txt-label);
  opacity: .8;
  align-items: center;
  width: 96%;
  margin: 0 auto;
  .playerScoreWrapper {
    background-color: var(--color-bg-tree-row-active);
    opacity: .6;
    margin: 0px;
    margin-top: 2px;
    padding: 2px 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:last-child {
      margin-bottom: 2px;
    }
    .score {
      min-width: 50px;
      text-align: center;
      margin-right: 2px;
    }
    .playerName {
      margin-left: 2px;
    }
  }
}


.songdetails{    color: #7c7c7c;}


/*@keyframes growUpDown {*/
    /*0% {*/
        /*height: 0;*/
    /*}*/
    /*100% {*/
        /*height: 100%;*/
    /*}*/
/*}*/
