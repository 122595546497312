// fonts
$default-font-family: 'Lato', sans-serif;

// colors
$gray-100: #edf3f2;
$gray-200: #e4e1e1;
$gray-300: #e8d7b1;
$gray-350: #dadae2;
$gray-400: #bdb0b0;
$gray-500: #bcafb0;
$gray-550: #808080;
$gray-600: #727373;
$gray-650: #434346;
$gray-700: #393939;
$gray-800: #323233;
$gray-900: #213131;

$red-000: #ff0000;
$red-100: #ec4bbb;
$red-200: #e63900;
$red-300: #a23f3f;
$red-400: #6f0418;

$yellow-100: #ffffaa;
$yellow-300: #fdd834;
$yellow-400: #e8d7b1;

$brown-100: burlywood;
$brown-500: #4c4141;

$green-100: #72eacc;
$green-200: #89ea91;
$green-300: lightseagreen;
$green-500: #4d6f6f;

$blue-100: #c9dbde;
$blue-200: #15c2dc;
$blue-250: #517575;
$blue-300: #141cb9;
$blue-350: #3b4142;
$blue-400: #385252;
$blue-900: #363638;

$purple-100: #9b14b9;