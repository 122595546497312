@import 'palette';
@import 'variables';
@import 'theme-light';
//@import 'theme-dark';
@import 'app';
@import 'header';
@import 'modals';
@import 'sidebar';
@import 'lyrics';
@import 'lyrics2';
@import 'viz';
@import 'modals/break-timer-modal.scss';
@import 'modals/leaderboard-modal';
@import 'modals/stats-by-player';
@import 'modals/stats-by-track';
@import 'overrides';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html,
body {
  height: 100%;
  margin: 0px;
}

html {
  touch-action: manipulation;
}

body {
  overflow: hidden;
  font-family: $default-font-family;

  background-color: var(--color-border-primary);
  margin-left: auto;
  margin-right: auto;
}

p {
  margin-bottom: 1em;
}

li {
  list-style: none;
}

fieldset {
  border-color: var(--color-txt-button);
  border-width: 1px;
  margin: 6px;
  flex: 1;
  color: var(--color-txt-label);
}

button {
  background: unset;
  color: #fdfdfd;
  border: 0;
  border-radius: 12px;
  cursor: pointer;
  font-size: 12px;

  &.dirty {
    font-weight: bold;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    border: initial !important;
  }
}

select {
  cursor: pointer !important;
  border: 1px solid black;
  background-color: var(--color-bg-button);
  color: var(--color-txt-button);
  margin-right: 5px;
  font-size: 14px;
}

input[type=text],
input[type=number],
input[type=file] {
  background-color: var(--color-bg-input);
  color: $color-txt-modal;
  //border-radius: 3px;
  //border: 1px solid $color-border-input;
}

input[type=text]:read-only,
input[type=number] {
  border-style: none;
  padding: 6px;
}

input[type=color] {
  border-radius: 3px;
  border: 1px solid $color-border-input;
  margin-right: 4px;
  height: 20px;
  padding: 0 2px;
  background-color: $color-bg-input;
  width: 40px;
}

input[type=radio] {
  cursor: pointer;
  vertical-align: text-top;
}

input[type=radio]:checked {
  cursor: default;
}

input[type=button] {
  height: 26px;
}

input[type=checkbox] {
  cursor: pointer;
  vertical-align: middle;
}

textarea {
  width: 100%;
  outline: none;
  resize: none;
  font-family: $default-font-family;
}

label {
  font-size: 12px;
  vertical-align: middle;
  padding-left: 2px;
  color: var(--color-txt-label);


  &.clickable {
    cursor: pointer;
  }
}

#root {
  height: 100%;
}

.app {
  height: 100%;
}