// fonts
$default-font-family: 'Lato', sans-serif;

// color-mapping
$bad: $red-200;
$good: $yellow-300;
$excellent: $green-200;
$perfect: $blue-200;

$color-border-input: black;
$color-border-lyrics-word-selected: $green-100;
$color-border-lyrics-word-scored: black;
$color-border-primary: $blue-100;
$color-border-modal: $blue-250;
$color-border-secondary: $blue-100;
$color-border-progress-bar: black;
$color-border-tree-row: black;
$color-border-tree-row-active: black;
$color-border-tree-row-dirty: $red-200;
$color-border-button-hover: $gray-100;
$color-border-push-button: $gray-200;

$color-bg-badge: $red-000;
$color-bg-blast-button: $gray-100;
$color-bg-blast-error: $red-200;
$color-bg-button: $gray-100;
$color-bg-controls: white;
$color-bg-input: $blue-250;
$color-bg-interaction-wrapper: $gray-100;
$color-bg-interaction-wrapper-viz: $gray-700;
$color-bg-list: white;
$color-bg-loading-indicator: $purple-100;
$color-bg-lyrics: white;
$color-bg-lyrics-bumper: $purple-100;
$color-bg-lyrics-section: $gray-100;
$color-bg-lyrics-section-mini: $yellow-300;
$color-bg-lyrics-section-selected: $green-100;
$color-bg-lyrics-word-playing: $blue-300;
$color-bg-modal: $gray-350;
$color-bg-modal-overlay: rgba(0,0,0,0.65);
$color-bg-progress-bar-bg: $green-300;
$color-bg-progress-bar-progress: white;
$color-bg-score-progress-bar: $gray-100;
$color-bg-score-progress-bar-progress: $green-500;
$color-bg-sidebar: $gray-100;
$color-bg-status-loading: $red-200;
$color-bg-status-success: $green-300;
$color-bg-timer: $gray-100;
$color-bg-tree-row-active: $yellow-400;
$color-bg-tree-row-player: $gray-100;
$color-bg-tree-row-playlist: $gray-200;
$color-bg-tree-row-track: $gray-100;

$color-txt-button: $blue-400;
$color-txt-button-footer: $brown-500;
$color-txt-label: $blue-400;
$color-txt-label-footer: $gray-700;
$color-txt-leaderboard-player: $blue-100;
$color-txt-leaderboard-self: $blue-250;
$color-txt-lyrics: black;
$color-txt-lyrics-line-num: $brown-500;
$color-txt-lyrics-section: $blue-400;
$color-text-lyrics-word-playing: $red-100;
$color-txt-match-owner: $gray-600;
$color-txt-modal: white;
$color-txt-modal-error: $red-200;
$color-txt-score-summary: $blue-400;
$color-txt-score-box-counter: $blue-400;
$color-txt-score-progress: $blue-400;
$color-txt-title: $blue-400;
$color-txt-tree-score-active: white;
$color-txt-tree-secondary: $gray-400;
$color-txt-tree-match-title: $blue-200;
$color-txt-tree-match-title-active: $yellow-400;
