.initializing {
  text-align: center;
  padding-top: 200px;
}

.sortable-list-item {
    position: relative;
    display: block;
    padding: 2px !important;
    margin-top: 2px;
    background-color: var(--color-bg-input);
    border: 1px solid var(--color-bg-input);
}
.sortable-list-item:first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.sortable-list-item:last-child {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.handle {
    cursor: grab !important;
    margin-top: initial !important; // TODO, shouldn't need but right now have to override userSettingsModal div :(
}

.help {
    font-size: 13px;
    font-style: italic;
    padding-left: 10px;
    padding-right: 7px;

    &.disabled {
      color: $gray-400;
    }
}

.playControlsWrapper {
    text-align: center;
    border-top-width: 0;
    padding: 10px;

    &.hasFocus {
      border: 0px solid var(--color-border-focus);
    }
}

.page {
    display: flex;
    flex-direction: column;
    height: 99%;
}

.pageBody {
    display: flex;
    flex-direction: row;
    flex: 1;
    max-height: 95vh;
    background-position-x: center;
    background-color: var(--color-border-primary);
    background-size: cover;
    background-repeat-x: no-repeat;
}

.linebreak{
  color: #579ea9;

}
.pageFooter {
    padding: 0px 10px 0px 10px;
    display: flex;
  justify-content: space-between;

    &.hasFocus {
      border: 0px solid var(--color-border-focus);
    }

  .blendMode {
    display: flex;
    margin-right: 6px;
    align-items: center;
    span {
      font-size: x-small;
    }
  }
  .audienceSwitch {
    margin: 0;
    font-size: 11px;
    line-height: 1;
    background-color: #9fc3c9;
    color: var(--color-txt-button);
    border-radius: 12px;
    border: 0px solid var(--color-border-push-button);
    min-width: 50px;
    margin-right:12px;
    height: 20px;
    padding: 4px 4px 0px 4px;
  }
  .audienceSwitch button {
   color:black
  }
}

.footerButton {
  color: var(--color-txt-button-footer);
  cursor: pointer;
  margin-left: 0px;
  margin-right: 3px;
  background: unset;
  min-width: unset;
  font-size: unset;
  font-family: $default-font-family;
  display: flex;
  align-items: flex-start;

  > .badge {
    display: inline-block;
    background-color: $red-000;
    border-radius: 16px;
    min-width: 16px;
    height: 16px;
    color: white;
    text-align: center;
    vertical-align: text-top;
    padding: 2px;
    font-size: 12px;
    margin-left: 4px;
    margin-top: 1px;
    &:hover {
      border: 1px solid var(--color-hover-txt-button-footer);
    }
  }
  .imgSm {
    padding-top: 2px;
  }
  img {
    width: 22px;
    vertical-align: bottom;
    border-radius: 22px;
    border: 1px solid var(--color-border-primary);
    &:hover {
      border: 1px solid var(--color-hover-txt-button-footer);
    }
  }

  .help{ font-size: 0.7em;
    text-transform: uppercase;
    font-style: normal;
    padding-top: 4px;}
  span {
    vertical-align: top;
    padding-left: 4px;
    font-size: 0.9em;
  }
  &:hover {
    color: var(--color-hover-txt-button-footer);
    text-decoration: underline;
  }
}

.versionInfo {
  color: $color-txt-label-footer;
  flex: 1;
  cursor: pointer;
}

.footerLeft {
  display: flex;
  justify-content: flex-start;
  font-size: smaller;
  line-height: 1.6;
  margin-top: 4px;
  flex: 2;
  align-items: center;
}

.footerCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 330px;
}

.timerbutton {
  color: #385352;
}

.footerRight {
  display: flex;
  justify-content: flex-end;
  flex: 2;

  .footerButton {
    margin-top: 2px;
  }
}
.volumeSettings {
  display: flex;
  align-items: center;
  color: #579ea9;
  padding-left: 30px;
  padding-right: 30px;
}

.textSettings {
  display: flex;
  align-items: center;
  color: #579ea9;
  padding-left: 20px;
  padding-right: 30px;
}
.imageSettings {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  color: #579ea9;
  border-left: 2px solid #a0c3c9;
  border-right: 2px solid #a0c3c9;
}
.footerSlider {
  color: var(--color-txt-button-footer);
  display: flex;
  align-items: center;
  cursor: pointer;

  div {
    &:hover {
      color: var(--color-hover-txt-button-footer);
    }
  }
  button {
    min-width: unset;
    background: unset;
    padding: 0;
    margin: 0
  }
  &.volume {
    justify-content: space-between;
    margin-left: 10px;
  }
  &.fontSize {
  margin-left: 12px;
  }
}



.playArea {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.interactionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    min-height: 0;
    border: 7px solid var(--color-border-primary);
    border-top-width: 0px;
  padding: 0px 10px 0px 10px;
    outline: none;
    &.spellMode {
      background-color: rgb(255 255 255 / 20%);
    }
    &.playMode {
      background-color: rgb(255 255 255 / 30%);
    }
    &.editMode {
      background-color: var(--color-bg-edit-mode);
    }
    &.hasFocus {
      border: 3px solid var(--color-border-focus);
      &.isPlaying {
        border-color: $excellent;
        border-radius: 2px;
        //border-style: dashed;
      }
    }
}


.socialModal {
  max-width: fit-content;
  max-height: 90% !important;
  top: 3% !important;
  border-radius: 2px !important;
  border: 7px solid var(--color-border-primary);
  background-color: var(--color-bg-sidebar) !important;
  z-index: 10000 !important;
}

.socialContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0px;
    border-left-width: 0px;
  width: 400px;
  background-color: #00000099;

    &.hasFocus {
      border: 3px solid var(--color-border-focus);
    }
}

.transcript {
    flex: 1 1 auto;
    position: relative;
    overflow-y:auto;
    border: none;
    padding-top: 5px;
}

.newLoadingIndicator {
  background: $color-bg-loading-indicator;
  position: relative;
  top: 25%;
  left: calc(50% - 140px);
  width: 280px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 25px;
  align-items: center;
  padding: 10px 10px 10px 0px;
}

.spinner {
  width: 50px;
  margin-left: 10px;
  margin-right: 8px;
  //margin-bottom: 25px;
}

.loadingContainer {
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  font-size: initial !important;
}

.loadingStatus span {
  color: $color-bg-status-success;
  font-style: italic;
  margin-left: 5px;

  &.isLoading {
    color: $color-bg-status-loading;
  }
}

.loading {
    cursor: wait !important;
}

@keyframes loadIn {
    0% {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scale(2,2) rotate(360deg);
        filter: blur(2px);
    }

    50% {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: scale(0.5,0.5) rotate(360deg);
        filter: blur(0px);
    }

    100% {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scale(3,3) rotate(0deg);
        filter: blur(2px);
    }
}

.saveContainer {
    border-radius: 12px;
    border: 6px solid var(--color-bg-modal);
    animation: move 0.4s;
    background-color: var(--color-bg-modal);
    position: fixed;
    z-index: 200000;
    top: 25%;
    padding: 8px 20px 15px 20px;
    left: calc(50% - 200px);
    width: 300px;
}

.leaderboard {
  display: flex;
  flex-direction: column;
  margin-top: -2px !important;
  height: 150px;
  max-width: 340px;
  font-size: 14px;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px dotted black;
    margin: 0
  }
  .player {
    color: var(--color-txt-leaderboard-player);
    flex: 1;
    padding: 2px 5px 2px 5px;

    &.self {
      color: $color-txt-leaderboard-self;
    }
  }
  .score {
    text-align: right;
    padding: 2px 5px 2px 5px;

    &.perfect2 {
      color: $perfect;
    }

    &.excellent2 {
      color: $excellent;
    }

    &.good2  {
      color: $good;
    }

    &.bad2 {
      color: $bad;
    }
  }

  .rank {
    text-align: right;
    width: 25px;
    padding: 2px 5px 2px 5px;
  }
}

.hidden {
    display: none !important;
}

.lyricEntry label {
    vertical-align:top;
}

.scoreSectionWrapper {

    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.errors {
    background-color:$color-bg-blast-error !important;
}


.error {
    border-top: 3px solid $color-bg-blast-error !important;
}

.song-edit-button {
    height: 27px;
    width: 24px;
    padding: 0;
    margin-right: 4px;
    min-width: initial;
    line-height: 1.1;
    font-size: 21px;
    text-align: center;
    border-radius: 4px;
}

.playlist-group {
    padding-right: 5px;
    text-align: right;
    line-height: 1.6;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex: 1;

    button {
      color: var(--color-txt-title);
      font-size: 16px;
      font-family: $default-font-family;
      text-align: left;
      padding: 0;
      max-width: fit-content;
    }

    .match {
      color: var(--color-txt-title);
      cursor: pointer;
    }
    .playlist {
      color: var(--color-bg-list);
      font-style: italic;
      font-size: 14px;
    }
}

.songSettingGroup {
    padding: 5px;
    display: flex;
    flex-flow: wrap;
    flex: 1;
    max-width: fit-content;
}

.songSetting {
    padding: 5px;
}

.options-row {
    flex: 1;
    /*max-width: 420px;*/
    padding-right: 20px;
    display: flex;
    justify-content: center;
}

.action-row {
  flex: 1;
  max-width: 230px; /* so buttons always wrap */
}
.action-row button {
    border-radius: 3px;
    margin: 5px 5px 0px 0px;
    width: 90px;
}
.action-row label {
    padding: 10px;
}

.gamersWrapper {
  display: flex;
}
.gamerSwitch {
  margin-left: 13px;
  //margin-right: 10px;
  button {
    margin-left: 3px;
    &:hover {
      border-radius: unset;
      border: 1px solid var(--color-hover-border-push-button);
    }
    &:disabled {
      opacity: .7;
    }
    &.active {
      cursor: default;
      background-color: var(--color-txt-button);
      color: var(--color-bg-button);
      border-color: var(--color-bg-button);
    }
  }
}
.mode-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  column-gap: 6px;
  padding: 0px;
  border-radius: 14px;
  margin-top: 2px;
  margin-bottom: 2px;
  border: 0px solid $color-border-input;
  height: fit-content;
  .pushButton {
    cursor: pointer;
    margin: 0;
    font-size: 11px;
    line-height: 1;
    background-color: var(--color-bg-button);
    color: var(--color-txt-button);
    border-radius: 12px;
    border: 0px solid var(--color-border-push-button);
    min-width: 50px;
    padding: 3px 10px 3px 10px;
    height: 20px;
    &:hover {
      box-shadow: 0px 6px 8px -4px rgba(0, 0, 0, 0.5);
      transform: scale3d(1.03, 1.03, 0.7);
    }
    &.active {
      cursor: default;
      background-color: var(--color-txt-button);
      color: var(--color-bg-button);
      border-color: var(--color-bg-button);
    }
  }
}

.spacer {
    padding-left:2em;
}

.timer {
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  background-color: #00000094 !important;
  color: #cbcbcb !important;
  width:100px !important;
  padding: 3px !important;

}

.scrubber {
    width:200px;
    cursor:pointer;
    margin-left:9px;
    margin-right:9px;
    margin-bottom:19px;
}

.split-button {
    display: flex;
    justify-content: flex-end;
}

.setup-row {
    display: flex;
    justify-content: center;
}

.setup-row div {
    flex: 1;
}

.line-num {
    margin-top: 10px;
    margin-left: 13px;
    margin-right: 3px;
    color: $color-txt-lyrics-line-num;
}

.times {
    padding-left: 65%;
}

.anim {
    animation: beat 0.1s linear;
}

.playUi {
    display: flex;
    justify-content: center;
}

.mobileControls {
    flex: 1;
  display: flex;
  justify-content: center;
}

.playControls {
  //flex: 2;
  button {
    background-color: #0000008f;
    margin-right: 5px;
    padding: 3px 10px 3px 10px;
    min-width: 60px;
  }
}

.blastButton {
  height: 68px;
  width: 158px;
  background-image: url('../../assets/img/blast-left3.svg');
  background-size: contain;
  background-repeat: no-repeat;
  &.mini {
    height: 34px;
    width: 79px;
    cursor: unset;
    &.inactive {
      background-image: unset;
      color: $purple-100;
      cursor: pointer;
    }
  }
  &.left {
    margin-left: 11px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

    &.blasting {
      margin-left: initial;
    }
  }
  &.right {
    margin-right: 11px;
    &.blasting {
      margin-right: initial;
    }
  }
  animation: growDown 0.4s;
  background-color: unset !important;
  border-radius: 6px;

    &:hover {
      border: none;
      background-image: url('../../assets/img/blast-it-red.svg')
    }
    &.blasting {
      &.bad1 {
        background-image: url('../../assets/img/blast-it-red.svg')
      }
      &.good1 {
        background-image: url('../../assets/img/blast-it-yellow.svg')
      }
      &.excellent1 {
        background-image: url('../../assets/img/blast-it-green.svg')
      }
      &.perfect1 {
        background-image: url('../../assets/img/blast-it-blue.svg')
      }
    }
}

.blastButton:disabled {
  border: none
}

.multiValueRow {
  display: flex;
  align-items: center;

  span {
    font-size: small;
    margin-left: 5px;
    margin-right: 5px;
  }

  input {
    width: 35px;
    text-align: end;
  }
}

.trackOwner {
  color: var(--color-txt-label);
  &.self {
    color: var(--color-txt-title);
  }
}

@keyframes growDown {
    0% {
        height: 0px;
    }
    100% {
        height: 80px;
    }
}

@keyframes beat {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes move {
    0% {
        top: 0px;
    }

    100% {
        top: 50px;
    }
}

.spotifyContainer {
  width: 350px;
  .main-wrapper {
    display: flex;

    img {
      width: 100px;
      height: 100px;
      margin-right: 5px;
    }
  }
}

.spotifyInfo {
  display: flex;
  align-items: center;
  img {
    width: 45px;
    height: 45px;
    margin-right: 5px;
  }
}

.react-countdown-clock {
  margin-left: auto;
  margin-right: auto;
  position:absolute;
  z-index: 10000;
  top: calc(50% - 200px); // must be 0.5 * COUNTDOWN_CLOCK_SIZE
}
