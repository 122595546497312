
.settingsContainer {
  padding-bottom: 5px;
  display: flex;
  border-top: 1px dotted $color-border-secondary;
  padding-top: 4px;
  margin-bottom: 0px;
  margin-top: 2px;
  margin-left: 8px;
  margin-right: 14px;

  .song-ui {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.wordcount{    font-size: 12px;
  text-transform: capitalize;
  float: right;
  color: white;
padding-right:20px}
.pointsperword{font-size:12px;
  text-transform: capitalize;
  float:right;
  color:white;
}

.headerWrapper {
  //border: 7px solid var(--color-border-primary);
  //border-top-width: 15px;
  //background-color: #0606062b;
  //background-color: #00000047;
  font-family: Montserrat;
  padding-top: 10px;
  padding-bottom: 18px;
  background: linear-gradient(90deg, rgba(0,0,0,0.40662202380952384) 0%, rgba(108,108,108,0.33659401260504207) 41%);

  div {
    display: flex;
  }
  &.hasFocus {
    border: 0px solid var(--color-border-focus);
  }
  .banner {
    .trackInfo {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px;
      background-color: #00D3FF;
      border-radius: 50%;
      left: -16px;
      position: relative;
      -webkit-box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.59);
      color:white;
      border:4px solid white;
      width:70px;
      height:65px;
      .score {
        padding: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: x-large;
        .points {
          color: white;
          font-weight: 700;
        }
        .pointInfo {
          min-width: 140px;
        }
      }
      span {
        color: #727373; // var(--color-border-primary);
      }
    }

    .lanes {
      flex-direction: column;
      width: 100%;
      margin-left: 12px;
      .divider {
        margin-right: 10px;
        height: 16px;
        .padLeft {
          width: 8px;
        }
        .padRight {
        }
        .scale {
          //background: linear-gradient(to right, $bad 40%, $good 70%, $excellent 90%, $perfect 100%);
          border: 2px solid white;
          width: 100%;

          .bad {
            width: 40%;
            border-right: 1px solid white;
            background-color: rgba($bad, .8) !important;
          }
          .good {
            width: 30%;
            border-right: 1px solid white;
            background-color: rgba($good, .8)!important;
          }
          .excellent {
            width: 20%;
            border-right: 1px solid white;
            background-color: rgba($excellent, .8)!important;
          }
          .perfect {
            width: 10%;
            background-color: rgba($perfect, .8) !important;
          }
        }
      }
      .lane0 {
        .scoreView {
          position: relative;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 1.3em;
          background-color: $bad;
          color: white;
          border: 1px solid white;
          margin-top: -5px;
          margin-bottom: -5px;
          top: -8px;
          border-radius: 36px;
          padding: 1px;
          padding-left: 10px;
          padding-right: 10px;
          font-weight: 700;
          -webkit-box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.59);
        }
        .scoreView:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 30%;
          width: 0;
          height: 0;
          border: 14px solid transparent;
          border-top-color: #ffffff;
          border-bottom: 0;
          margin-bottom: -12px;
          border-top-width: 12px;
          border-right-width: 6px;
          border-left-width: 6px;
        }
      }
      .lane1 {
        .scoreView {
          position: relative;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 1.3em;
          background-color: $bad;
          color: white;
          border: 1px solid white;
          margin-top: -5px;
          margin-bottom: -5px;
          top: 8px;
          border-radius: 36px;
          padding: 1px;
          padding-left: 10px;
          padding-right: 10px;
          font-weight: 700;
          -webkit-box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.59);
        }
        .scoreView:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 30%;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 12px solid white;
          top: -12px;
        }
      }
    }

    justify-content: space-between;

    .playerInfo {
      width:98%;
      color: $purple-100;
      margin-bottom:20px;
      color:black;
      padding-left: 10px;
      /*  background-color: rgba(0, 0, 0, 0.3019607843);
      -webkit-border-top-left-radius: 16px;
        -webkit-border-top-right-radius: 16px;
        -moz-border-radius-topleft: 16px;
        -moz-border-radius-topright: 16px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;*/
        span {
          padding: 0 10px;
          background-color: rgb(38 38 38 / 37%);
        }
        .title {
          button {
            &.x-large {
              font-size: x-large;
              font-weight: bold;
            }
            font-size: 0.8vw;
            color: white;
            background-color: rgb(145 145 145 / 37%);
            border-radius:0px;
          }
        }
      }
      .players {
        position: relative;
        height: 145%;
        flex-direction: column;
        justify-content: space-between;
        margin: 5px;
        left: 30%;
         width:10px;
        .title {
          color: var(--color-bg-list);
        //text-transform: uppercase;
          padding: 3px;
          border-radius: 3px;
          font-size: 1vw;
  //        font-weight: bold;
          text-wrap: nowrap;

        }
      }
      .playerMenu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3px;

        .chatNewTrackButton {
          margin-top: 0px;
          padding: 2px;
        }
        .labeledSelect {
          margin-bottom: 4px;
          align-items: center;
          select {
            font-size: 18px;
            background-color: var(--color-bg-blast-button);
            border-radius: 3px;
            border: 1px solid black;
            padding: 5px;
            margin-right: 0px;
            color: var(--color-txt-score-progress);
            min-width: 200px;
          }
        }
      }

      .left {
        max-width: 60%;
        min-width: 40%;
        border-radius: 24px;
        margin: 12px 12px 0px 12px;
        white-space: nowrap;
        max-width: none;
        width: auto;
        min-width: auto;
        /*background-color:#0000005c;
        border: 6px solid white;*/
      .edge {
        justify-content: flex-end;
      }
      .body {
        align-items: flex-start;
      }
    }
    .edge {
      display: flex;
      min-height: 100%;
      flex-direction: column;
      justify-content: space-between;
      color: var(--color-txt-label);

      button {
        width: 17px;
        border-radius: 3px;
        padding: 2px;

      }
      button:hover {
        border-color: var(--color-hover-border-push-button);
      }

      .top {
        cursor: pointer;
        margin-top: 3px;
        margin-left: 3px;

      }

      .bottom {
        cursor: pointer;
        margin-left: 3px;
        margin-bottom: 3px;

      }
    }
    .body {
      flex-direction: column;

      width: 100%;
      height: 100%;
    }
    .center {
      flex-direction: column;
      justify-content: center;
      &.withPlayer {
        justify-content: space-between;
        align-items: center;
        height: fit-content;
      }
    }
    .right {
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      margin: 12px 12px 0px 0px;
      padding: 8px;
      border-radius: 24px;
      background-color: rgb(255 255 255 / 29%);
      border: 6px solid white;
      .scoreStuff {
        width: 100%;
        height: 100%;
        align-items: center;
        padding-left: 5px;

      }

      .body {
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,0.37160801820728295) 49%, rgba(108,108,108,0.517266281512605) 50%);
        border: 2px solid #ffffffbf;
        border-radius: 12px;
      }
    }

    .trackBadge {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 16px;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      margin-top: -20px;
      .currTrackTitle {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        cursor: pointer;
        margin-left: 0px;
        flex: 1;
        text-transform: lowercase;
        font-size: x-large;

        button {
          color: var(--color-txt-title);
          font-family: Montserrat;
          font-size: 2vw;
          text-transform: none;    color: white;
          text-align: center;
          padding: 0;
          max-width: fit-content;
          font-weight:700;

        }
        .artist {
          color: white;
          font-style: normal;
          text-transform: uppercase;
          font-size: 1vw;
          margin-top: -5px;
          padding-bottom:16px;
          &:hover {
            font-style: normal;
          }
        }

        &.disabled {
          cursor: default;
        }

        &:hover {
          color: var(--color-border-button-hover);

        }
      }
    }
  }
}

.title-bg{
  background-color: white;
  padding:2px 20px 2px 20px;
  color:black;
  border-radius: 12px;
}
.menutrack{    background-color: rgb(255 255 255 / 20%) !important;
  padding: 4px 8px 4px 8px;
  border-radius: 0px 12px 12px 0px !important;
  padding: 4px 12px 4px 12px;
}

.word-container.target.word{     color: black !important;}
.large{ background-color: rgb(255 255 255 / 40%) !important;
  border-radius: 12px 0px 0px 12px !important;
  padding: 4px 12px 4px 12px;
margin-right:1px}