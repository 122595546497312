$far: 98%;
$close: 50%;


.word-container.target.word{
color:black !important;
}


.word-container {
    cursor: pointer;
    display: inline-block;

  &:hover div {
    opacity: 0.5;
  }
  &.target  {
    padding: 4px !important;
  box-shadow: -1px 3px 5px 0px rgba(0,0,0,0.59);
    -webkit-box-shadow: -1px 3px 5px 0px rgba(0,0,0,0.59);
    -moz-box-shadow: -1px 3px 5px 0px rgba(0,0,0,0.59);
    background: rgb(255,255,255);
    background: linear-gradient(45deg, rgba(255,255,255,0.10270045518207283) 0%, rgba(255,255,255,1) 29%, rgba(255,255,255,1) 75%, rgba(255,255,255,0.08309261204481788) 100%);
    border-width: 2px !important;
    border-style: solid !important;
    border-color: white !important;
    border-image: initial !important;
    color: #444342 !important  ;
}

&.perfect {
background-color: $perfect;
}
&.excellent {
&.ahead {
  &.close {
    background: linear-gradient(to left, $excellent, $close, $perfect) !important;
  }
  &.far {
    background: linear-gradient(to left, $excellent, $far, $perfect) !important;
  }
}
&.behind {
  &.close {
    background: linear-gradient(to right, $excellent, $close, $perfect) !important;
  }
  &.far {
    background: linear-gradient(to right, $excellent, $far, $perfect) !important;
  }
}
}
&.good {
&.ahead {
  &.close {
    background: linear-gradient(to left, $good, $close, $excellent) !important;
  }
  &.far {
    background: linear-gradient(to left, $good, $far, $excellent) !important;
  }
}
&.behind {
  &.close {
    background: linear-gradient(to right, $good, $close, $excellent) !important;
  }
  &.far {
    background: linear-gradient(to right, $good, $far, $excellent) !important;
  }
}
}
&.bad {
&.ahead {
  &.close {
    background: linear-gradient(to left, $bad, $close, $good) !important;
  }
  &.far {
    background: linear-gradient(to left, $bad, $far, $good) !important;
  }
}
&.behind {
  &.close {
    background: linear-gradient(to right, $bad, $close, $good) !important;
  }
  &.far {
    background: linear-gradient(to right, $bad, $far, $good) !important;
  }
}
}

&.selected  {
/*-webkit-box-shadow: 0px 0px 25px $color-bg-lyrics-word-playing;
-moz-box-shadow: 0px 0px 25px $color-bg-lyrics-word-playing;
box-shadow: 0px 0px 25px $color-bg-lyrics-word-playing;
//text-transform: uppercase;*/
  box-shadow: none !important;
  text-transform: none !important;
  background-color: rgb(176, 91, 216) !important;
font-weight: bolder;
opacity: 1.0;
}

&.play, &.read {
margin: 3px;
padding: 6px;
border-radius: 6px;
text-align: center;

&.selected  {
  animation: beat 0.1s linear;
}

&.scored {
  border: 1px solid white !important;
  padding: 5px;
  color: black;

  &.target  {
    border-color: $color-border-lyrics-word-selected !important;
  }
}
}
&.viz {
position: absolute;
opacity: inherit;
//opacity: 0.1;
z-index: inherit;

&.selected  {
  //animation: viz 0.05s linear;
  font-size: 27px;
  font-weight: initial;
  z-index: 100000;
  //background-color: $color-text-lyrics-word-playing;
}

&.scored {
  /* border: 1px solid black !important; */
  -webkit-box-shadow: 0px 6px 8px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 6px 8px -4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 6px 8px -4px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}
&.passedLine {
  opacity: 0.1;
}
&.aheadLine {
  opacity: 0.5;
}
&.target  {
  border-width: 1px !important;
}

}

&.spelling {
.word {
  display: none
}
.spellingTarget {
  display: initial;
}
}
}

.masked {
margin-left: 10px;
border: 1px solid black !important;
.word {
  opacity: 0.0;
}
}

.word {
display: inline-block;
padding: 7px;
text-align: center;
font-weight: normal; // using numbers > 500 results problematic bolding effect for letters with diacritical marks!

}

.spellingTarget {
color: green;
opacity: 1.0 !important;
display: none;
background-color: white;
}


.word-timestamp {
font-size: 12px;
margin-left: 4px;
margin-right: 4px;
margin-bottom: 4px;
}
