.statsByTrackTable {
  min-width: 100%;
  max-width: 340px;
  flex-direction: column;
  overflow: scroll;
  //height: 800px; // TODO

  .table-container {
    width: 100%;
    overflow: auto;
    position: relative;
  }

  .table {
    border-collapse: collapse;
    width: 100%;
  }

  th {
    padding: 2px;
    background-color: var(--color-bg-modal);
    height: 100%;
  }
  td {
    padding: 1px;
    height: 100%;
  }

  .sticky-header {
    min-width: 155px;
    max-width: 155px;
    word-break: break-word;
    position: sticky;
    top: 0;
    z-index: 2; /* Ensures headers appear on top */
    .corner {
      flex-direction: column;
      align-items: center;
      .visibility {
        justify-content: center;
      }
    ;
    }
    .playerScore {
      height: 100%;
      background-color: #d7e4e6;
      padding-top: 6px;
      border-radius: 8px;
      .scoreWrapper {
        width: 90%;
        justify-content: center;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-bottom: 8px;
      }
    }
    .matchRank {
      font-size: medium;
      color: white;
      background-color: #95b1b4;
      padding: 3px;
      a: 12px;
      border-radius: 66px;
      width: 20px;
      height: 20px;
      margin-bottom: 8px;
      justify-content: center;
      background-image: linear-gradient(to bottom, #87c9f7, #2c7d8a);
      border: 2px solid white;
    }
  }

  .sticky-label {
    position: sticky;
    left: 0;
    background-color: var(--color-bg-modal);
    z-index: 1;
    font-size: medium;
    min-width: 340px;
    height: 36px;

    .playlistInfo {

      font-size: 0.9vw;
      align-items: center;
      justify-content: flex-end;
      padding: 10px;
      margin-right: 0px;
      cursor: pointer;
      background-color:#8aafb3;
      color:white;
      text-transform: uppercase;
      letter-spacing: .2rem;
    }
  }

  .playerScore {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &.isPlaylist {
      border-radius: 0px;
      font-size: large;
      height: 38px;
      background-color:#b4cdd0;
      justify-content: center;
    }

    .playerBadge {
      font-size: 1.2em;
      font-family:Montserrat;
      font-weight: 600;
      color: #466265;
      &.guestName {
        height: 1.6em;
      }
    }
    .score {
      font-size: x-large;
      background-color: var(--color-bg-score-progress-bar);
      border: 0px solid black;
      border-radius: 0px;
      min-width: 40px;
      padding: 4px;
      color: var(--color-txt-score-progress);
      height: 100%;
      justify-content: center;
    }
  }
  .trackBadge {
    padding-left: 10px;
    padding-top: 3px;
    display: flex;
    justify-content: space-between;

    .currTrackTitle {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;
      flex-direction: column;
      cursor: pointer;
      margin-right: 10px;
      flex: 1;
      font-size: large;

      button {
        color: #385e63;
        background-color: unset;
        font-family: Montserrat;
        font-size: large;
        text-align: center;
        padding: 0;
        max-width: fit-content;
        font-weight: 700;
        &:hover {
          border: none;
        }

      }
      .artist {
        color: var(--color-txt-lyrics);
        font-family: Montserrat;
        font-size:0.85em;
        color:#7cadb2
      }

      &.disabled {
        cursor: default;
      }

    }
  }
  .trackInfo {
    z-index: 1;
    min-width: 94px;
    font-size: small;
    color: var(--color-txt-tile);
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    padding-top: 0px;
    background-color: var(--color-bg-blast-button);
    border-radius: 4px;
    .score {
      padding: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: large;

      .points {
        color: #000;
      }
      .pointInfo {
        min-width: 140px;
      }
    }
    span {
      color: #c1c1c1; // var(--color-border-primary);
    }
  }

}

.statsByTrackTable .playerScore.isPlaylist .score{
  width:100%;
  font-size:1.1em;
  font-weight: 600;
  font-family: 'Montserrat';
  padding-top: 6px;
  border-radius:0px;
  display: inline-table;
  text-align:center;
  line-height: 40px;
  color:#444847;
}

.statsByTrackTable .playerScore .score{
  font-size:1.1em;
  border-radius:24px;
  padding:8px;
  font-family: 'Montserrat';
  color:white;
  font-weight: 600;
}

.statsByTrackTable .sticky-header .playerScore .scoreWrapper .score{
  border-radius:10px;
  width:86%;
  border: 2px solid white;
  font-family: 'Montserrat';
  background: linear-gradient(45deg, rgb(0 0 0 / 0%) 0%, rgb(255 255 255 / 41%) 29%, rgba(255, 255, 255, 0.92) 75%, rgba(255, 255, 255, 0.05) 100%);  color:#444847;
  font-size:1.4em;
  font-weight: 600;
  -webkit-box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.29);
  -moz-box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.29);
  box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.29);
}