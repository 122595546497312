/*

.word {
  //padding: 8px 12px 8px 12px;
  //margin:4px;
  border-radius: 6px;
}
.word-container.play.scored, .word-container.read.scored {
  border: 0px solid #000 !important;
}
.word-container.play, .word-container.read {
  border-radius: 6px !important;
  //margin-right: 6px !important;
}
.word-container.target {
  border: 0px !important;
  background-color: white !important;
  color: black !important;
}
.word-container.selected {
  box-shadow: none !important;
  //text-transform: none !important;
  background-color: #B05BD8 !important;
}
.word-container.play.scored, .word-container.read.scored {
  //border: 0px !important;
  //margin-right: 6px !important;
}

 */

//.headerWrapper {
//  border:0px !important;
//  background-color: #0606062b !important;
//}
.interactionWrapper{
  border:0px !important
}
.playControlsWrapper{
  //border:0px !important;
  background-color: #0606062b !important;
}
.interactionWrapper.playMode {
  background-color:unset !important;
  background-color: hsl(0deg 0% 100% / 0%) !important;
}
.pageFooter {
  //border:0px !important;
}